<template>
  <div>
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Add New Surgery</h1>
    </div>
    <surgery-form
      :loaders="loaders.surgery"
      button-text="Save"
      @storeSurgery="storeSurgery"
    />
  </div>
</template>

<script>
import SurgeryForm from "./SurgeryForm";
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";
export default {
  components :{
    SurgeryForm,BackButton
  },
  name: "StoreSurgery",
  data() {
    return {
      loaders: {
        surgery: false,
      },
      response: {
        categories: [],
      },
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {

    storeSurgery(event) {
      let vm = this;
      vm.loaders.surgery = true
      let formData = new FormData();
      _.each(event, (value, key) => {
        formData.append(key, value);
      });

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };

      axios.post(this.$store.getters.getBaseUrl + `/api/content/surgeries/store`, formData, {config}).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.$notify.success({
          title: 'Success',
          message: 'Surgery created successfully.'
        });
        formData = '';
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
          : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Surgeries',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }).finally(() => vm.loaders.surgery = false )
    },
  },

}
</script>

<style scoped>

</style>
