<template>
  <el-dialog
    :title="title"
    :visible.sync="modalVisible"
    width="36%"
    :before-close="handleClose"
    :close-on-click-modal="false">
    <el-form ref="urlForm" :model="form" :rules="rules">
      <div class="row px-0">
        <div class="col-12">
          <p class="label-text mb-2">URL*</p>
          <el-form-item prop="url">
            <el-input placeholder="Address" v-model="form.url"></el-input>
          </el-form-item>
        </div>
      </div>
      <div class="row mx-0 px-0">
        <p class="label-text col-12 pl-0 mb-2">Use Website Data</p>
        <div class="col mr-2 pr-0 checkbox-boundary">
          <el-checkbox class="green-checkbox w-100 pt-3" label="Yes" :value="form.is_facebook"
                       @change="form.is_facebook=!form.is_facebook"></el-checkbox>
        </div>
        <div class="col ml-2 pr-0 checkbox-boundary">
          <el-checkbox class="green-checkbox w-100 pt-3" label="No" :value="!form.is_facebook"
                       @change="form.is_facebook=!form.is_facebook"></el-checkbox>
        </div>
      </div>
      <div class="text-right mt-4">
        <el-button class="cancel-btn option-btn-font" @click="handleClose">Cancel</el-button>
        <el-button class="btn-primary-action option-btn-font pl-5 pr-5 br-4" :disabled="loading" @click="handleSubmit">Save</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: "FaqModal",
  props: ['title','modalVisible','formData','loading'],
  watch: {
    formData: function ({url,is_facebook}){
      this.form.url = url
      this.form.is_facebook = !!(is_facebook)
    }
  },
  data() {
    const httpsValidation = (rule,value,callback) => {
      if (!(/^https:\/\/(.*)/.test(value))){
        callback(new Error('Please enter https url'))
      }else {
        callback()
      }
    }
    return {
      form: {
        url: '',
        is_facebook: true
      },
      id: null,
      rules:{
        url:[
          {required:true, type:'url', message:'Please Enter the url',trigger:'blur'},
          {validator:httpsValidation,trigger: 'blur'}
        ],
      }
    }
  },
  methods: {
    handleClose(){
      this.$refs.urlForm.resetFields(['url'])
      this.$emit('close',false);
    },
    handleSubmit(){
      let validity = true
      this.$refs['urlForm'].validate((valid)=>{
        if (!valid){
          validity = false
          return false
        }
      })
      if (validity) this.$emit('submit', this.form)
    }
  }
}
</script>

<style scoped>

</style>
