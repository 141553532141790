<template>
  <div class="ml-4 mr-4 mt-4">
    <div class="forms" v-loading="loaders">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Title"
                  label="Title*" v-model="request.surgeryForm.title"
                  placeholder="Title">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Category</label>
                <el-select
                  class="w-100"
                  v-model="request.surgeryForm.category"
                  placeholder="Select Category"
                  @change="onChangeSurgeryCategory"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Category"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.categories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Sub Category</label>
                <el-select
                  class="w-100 mb-3"
                  v-model="request.surgeryForm.subCategory"
                  placeholder="Select Sub Category"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Sub Category"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.subCategories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-6 col-md-6 d-flex justify-content-end align-items-center">
                <div class="img-upload" :class="{'w-100':!imageExist}">
                  <label class="form-control-label" for="profileImage">Image</label>
                  <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg" class="form-control mb-1" @change="onImageChange">
                  <span class="font-size-12px">Recommended image size 500x300 px. Must be less than 500kb.</span>
                </div>
                <el-image
                  v-loading="request.loaders.image"
                  class="img-size pl-2"
                  :class="{'d-none':!imageExist}"
                  :src="request.surgeryForm.imagePreview"
                  @load="handleLoad"
                  @error="handleLoad"
                />
              </div>

            </div>
            <div class="row mt-3">
              <div class="col-lg-6 col-md-6">
                <base-input label="Status">
                  <el-select
                    class="w-100"
                    label="Status"
                    v-model="request.surgeryForm.status">
                    <el-option
                      v-for="option in dropdowns.status"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col equal-height-columns">
                <label class="form-control-label">Content*</label>
                <editor
                  v-model="request.surgeryForm.content"
                  :api-key=editorApiKey
                  :init=editorOption
                />
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit('surgeryForm')"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          :disabled="loaders"
          type="submit">
          {{ buttonText }}
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {store} from "@/vuex/store";
import Helper from "@/util/globalHelpers";

export default {
  name: "SurgeryForm",
  props: ['surgeryItemToEdit', 'loaders', 'buttonText'],

  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      modals: {
        detailsModal: false,
        checklistItemsModal: false,
      },
      request: {
        loaders:{
          image: false,
        },
        surgeryForm: {
          name: '',
          category: 0,
          subCategory: 0,
          image: '',
          imagePreview: '',
          status: 1,
          content: '',
        },
      },
      response: {
        categories: [],
        subCategories: [],
      },
      dropdowns: {
        status: [
          {
          value: 1,
          label: 'Active'
          },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
    }
  },
  mounted() {
    this.fetchSurgeryCategories();
  },
  watch: {
    surgeryItemToEdit(newVal) {
      this.request.loaders.image = true
      this.request.surgeryForm.title = newVal.title ?? '';
      this.request.surgeryForm.category = newVal.surgery_category_id;
      this.request.surgeryForm.subCategory = newVal.surgery_sub_category_id;
      this.request.surgeryForm.content = newVal.content ?? '';
      this.request.surgeryForm.status = newVal.status;
      if (newVal.hasOwnProperty('surgery_category')) {
        this.fetchSurgerySubCategories(newVal.surgery_category_id);
      }
      this.request.surgeryForm.imagePreview = newVal.image
    }
  },
  methods: {
    onImageChange(e){
      this.request.surgeryForm.image = e.target.files[0];
    },

    handleKeyPressForEditor(value) {
      this.request.surgeryForm.content = value;
    },

    onChangeSurgeryCategory(surgeryCategory){
      if (surgeryCategory == 0){
        this.response.subCategories = [];
        this.request.surgeryForm.subCategory = 0;
      }
      else {
        this.request.surgeryForm.subCategory = 0;
        this.fetchSurgerySubCategories(surgeryCategory);
      }
    },

    fetchSurgeryCategories() {
      let vm = this;
      axios.get(this.$store.getters.getBaseUrl + '/api/content/surgery-categories/all')
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.categories= response.data.data;
        })
        .catch((error) => console.error(error.response));
    },

    fetchSurgerySubCategories(id) {
      let vm = this;
      if(id!=0){
        axios.get(this.$store.getters.getBaseUrl + '/api/content/surgery-sub-categories/by-category-id?categoryId='+id)
          .then((response) => {
            vm.$store.commit('toggleSessionCheck', true)
            vm.$store.commit('updateSessionTime', 0)
            vm.response.subCategories = response.data.data;
          })
          .catch((error) => console.error(error.response));
      }

    },


    async onSubmit(formName) {
      const isSurgeryFormValid = await this.$refs['formValidator'].validate();

      if (isSurgeryFormValid) {
        this.request.surgeryForm.content = Helper.addCustomStylingToContent(this.request.surgeryForm.content)
        if (this.$route.name === 'StoreSurgery') {
          this.$emit('storeSurgery', this.request.surgeryForm);
        } else if (this.$route.name === 'EditSurgery') {
          this.$emit('updateSurgery', this.request.surgeryForm);
        } else {
          console.log('Hello Else World');
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },
    handleLoad() {
      this.request.loaders.image = false
    }
  },
  computed: {
    getContentValue() {
      return this.request.surgeryForm.content;
    },
    imageExist() {
      return this.request.surgeryForm.imagePreview !== ''
        && this.request.surgeryForm.imagePreview
        && this.request.surgeryForm.image === ''
    }
  },
}
</script>

<style scoped>

.img-size{
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload{
  width: calc(100% - 120px);
  display: inline-block;
}

</style>
